<template>
  <b-card
    v-if="contact"
    no-body
    class="border-primary"
  >
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5 class="mb-0">
        جهة الاتصال
      </h5>
    </b-card-header>

    <b-card-body>
      <ul class="list-unstyled my-1">
        <li>
          <span class="align-middle">الإسم:
            {{ contact.name }}</span>
        </li>
        <li>
          <span class="align-middle">البريد الإلكتروني:
            {{ contact.email }}</span>
        </li>
        <li>
          <span class="align-middle">الجوال:
            {{ contact.mobile }}</span>
        </li>
      </ul>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BBadge, BButton,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import * as moment from 'moment';
import router from '@/router';

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
  },
  props: {
    contact: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    moment() {
      return moment();
    },
  },
};
</script>

<style>

</style>
