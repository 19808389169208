import axios from '@axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchContracts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/contract', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchContract(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/contract/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchCost(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/contract/cost/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateContract(ctx, { contractData, id }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/contract/${id}`, contractData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteContract(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/contract/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addContract(ctx, contractData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/contract', contractData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchClients(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/client/list')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchContacts(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/client-contact/list')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchContractOptions(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/contract/options')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    downloadContract(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/contract/${id}/download`, {
            responseType: 'blob',
            headers: {
              Accept: 'application/pdf',
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchEvaluators(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/company/permission/evaluation_approve')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
