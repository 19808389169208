<template>
  <div v-if="contract">
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <contract-details-card :contract="contract" />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <contact-details-card :contact="contract.contact" />
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-card
      v-if="contract && !showUpdateCostsForm"
      no-body
      class="mb-1"
    >
      <div class="card-header">
        <!-- Title -->
        <div>
          <b-card-title>
            بيانات الأتعاب <b-button
              v-if="$can('update', 'contract')"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="btn-sm mr-2"
              variant="primary"
              @click="showUpdateCostsForm = true"
            >
              <feather-icon
                icon="EditIcon"
                class="mr-25"
              />
              <span>تعديل</span>
            </b-button>
          </b-card-title>
        </div>
      </div>
      <div class="m-2">
        <b-table
          small
          :fields="fields"
          :items="contract.costs"
          responsive="sm"
        >
          <!-- A custom formatted column -->
          <template #cell(type)="data">
            <b-badge
              pill
              :variant="data.item.type == 'fixed'? 'primary':'dark'"
            >
              {{ $t(data.item.type) }}
            </b-badge>
          </template>

          <template #cell(property_classification)="data">
            <span v-if="data.item.type == 'variable'">
              {{ data.item.property_classification.name }}
            </span>
            <span v-else>
              -
            </span>
          </template>
          <template #cell(property_type)="data">
            <span v-if="data.item.type == 'variable'">
              {{ data.item.property_type.name }}
            </span>
            <span v-else>
              -
            </span>
          </template>

          <!-- A virtual composite column -->
          <template #cell(cost)="data">
            {{ data.value }} ريال
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item :to="{ name: 'contracts-cost-evaluation-request-add', params: { id: data.item.id } }">
                <feather-icon icon="PlusCircleIcon" />
                <span class="align-middle ml-50">طلب تقييم</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </div>
    </b-card>
    <b-card
      v-if="contract && showUpdateCostsForm"
      no-body
      class="mb-1"
    >
      <div class="card-header">
        <!-- Title -->
        <div>
          <b-card-title>
            بيانات الأتعاب <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="btn-sm mr-2"
              variant="primary"
              @click="addItem"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>إضافة عقار</span>
            </b-button>
          </b-card-title>
        </div>
      </div>
      <div class="m-2">
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <b-form
            class="mt-1"
            @submit.prevent="handleSubmit(updateContract)"
            @reset.prevent="resetForm"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
              >
                <b-alert
                  v-model="showMessage"
                  dismissible
                  :variant="messageType"
                >
                  <div class="alert-body">
                    <feather-icon
                      class="mr-25"
                      icon="InfoIcon"
                    />
                    <span
                      class="ml-25"
                      v-html="message"
                    />
                  </div>
                </b-alert>
              </b-col>
            </b-row>
            <b-row
              v-for="(item, index) in contract.costs"
              :id="item.id"
              :key="item.id"
              ref="row"
            >
              <b-col
                cols="12"
                md="3"
                lg="3"
              >
                <!-- Field: type -->
                <validation-provider
                  #default="validationContext"
                  vid="type"
                  name="type"
                  rules="required"
                >
                  <b-form-group
                    label="نوع الأتعاب"
                    label-for="type"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      v-model="item.type"
                      :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                      :options="costTypes"
                      :reduce="(val) => val.value"
                      :clearable="false"
                      input-id="type"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                v-if="item.type === 'variable'"
                cols="12"
                md="2"
                lg="2"
              >
                <!-- Field: property_classification_id -->
                <validation-provider
                  #default="validationContext"
                  vid="property_classification_id"
                  name="property_classification_id"
                  rules="required"
                >
                  <b-form-group
                    label="تصنيف العقار"
                    label-for="property_classification_id"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      v-model="item.property_classification_id"
                      :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                      :options="classifications"
                      :reduce="(val) => val.value"
                      :clearable="false"
                      input-id="property_classification_id"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                v-if="item.type === 'variable'"
                cols="12"
                md="3"
                lg="3"
              >
                <!-- Field: property_type_id -->
                <validation-provider
                  #default="validationContext"
                  vid="property_type_id"
                  name="property_type_id"
                  rules="required"
                >
                  <b-form-group
                    label="نوع العقار"
                    label-for="property_type_id"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      v-model="item.property_type_id"
                      :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                      :options="types"
                      :reduce="(val) => val.value"
                      :clearable="false"
                      input-id="property_type_id"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="3"
                lg="3"
              >
                <!-- Field: cost -->
                <validation-provider
                  #default="validationContext"
                  vid="cost"
                  name="cost"
                  rules="required"
                >
                  <b-form-group
                    label="أتعاب تقييم العقار"
                    label-for="cost"
                    :state="getValidationState(validationContext)"
                  >
                    <b-form-input
                      id="cost"
                      v-model="item.cost"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!-- Remove Button -->
              <b-col
                cols="12"
                md="1"
                lg="1"
                class="mb-50"
              >
                <b-button
                  v-if="contract.costs.length > 1"
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  @click="removeItem(index)"
                >
                  <feather-icon
                    icon="XIcon"
                    class="mr-25"
                  />
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col>
                <b-button
                  variant="primary"
                  type="submit"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                >
                  حفظ التغييرات
                </b-button>
                <b-button
                  variant="outline-dark"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="resetForm"
                >
                  إعادة ضبط
                </b-button>
                <b-button
                  variant="outline-warning"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="showUpdateCostsForm = false"
                >
                  إلغاء
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </b-card>
    <evaluation-request-list />
  </div>
</template>

<script>
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import vSelect from 'vue-select';
import {
  BAlert, BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BForm, BButton, BMedia, BAvatar, BLink, BDropdown, BDropdownItem, BPagination, BRow, BCol, BTable, BProgress, BBadge, BCard, BCardTitle,
} from 'bootstrap-vue';
import router from '@/router';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import formValidation from '@core/comp-functions/forms/form-validation';
import Ripple from 'vue-ripple-directive';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { alertMessageMixin } from '@core/mixins/ui/feedback';
import { heightTransition } from '@core/mixins/ui/transition';
import useEvaluationRequestList from '../../evaluationRequest/contract-evaluationRequest-list/useEvaluationRequestList';
import contractStore from '../contractStore';
import EvaluationRequestList from '../../evaluationRequest/contract-evaluationRequest-list/EvaluationRequestList.vue';
import ContactDetailsCard from './ContactDetailsCard.vue';
import ContractDetailsCard from './ContractDetailsCard.vue';

export default {
  directives: {
    Ripple,
  },
  components: {
    EvaluationRequestList,
    ContractDetailsCard,
    ContactDetailsCard,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BTable,
    BProgress,
    BBadge,
    BCard,
    BAlert,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BForm,
    BButton,
    BMedia,
    BAvatar,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
  },
  mixins: [alertMessageMixin, heightTransition],
  data() {
    return {
      id: router.currentRoute.params.id,
      showUpdateCostsForm: false,
      fields: [
        { key: 'type', label: 'نوع الأتعاب' },
        { key: 'property_classification', label: 'تصنيف العقار' },
        { key: 'property_type', label: 'نوع العقار' },
        { key: 'cost', label: 'أتعاب تقييم العقار' },
        { key: 'actions', label: 'الإجراءات' },

      ],
      items: [],
    };
  },
  methods: {
    async fetchContract() {
      this.$store.dispatch('contract/fetchContract', { id: router.currentRoute.params.id })
        .then((response) => {
          const { data } = response.data;
          this.contract = data;
          this.showUpdateCostsForm = false;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            contract.value = undefined;
          }
        });
    },
    updateContract() {
      // Remove certified_evaluator_id if it's null
      if (this.contract.certified_evaluator_id === null) {
        delete this.contract.certified_evaluator_id;
      }
      // Filter out entities with null cost
      this.contract.costs.forEach(cost => {
        Object.keys(cost).forEach(key => {
          if (cost[key] === null) {
            delete cost[key];
          }
        });
      }); 
      this.$store
        .dispatch('contract/updateContract', { contractData: this.contract, id: this.contract.id })
        .then(async (response) => {
          this.$toast.success(response.data.message);
          await this.fetchContract();
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 422) {
              this.$toast.error(err.response.data.message);
              const { message, errors } = err.response.data;
              if (errors) {
                const errorMessage = {
                  message: '',
                  type: 'danger',
                };
                Object.values(errors).forEach((error) => {
                  errorMessage.message += `${error[0]}<br>`;
                });
                this.displayMessage(errorMessage);
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  text: message,
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                },
              });
            } else {
              this.$toast.error(err.response.data.error);
            }
          }
          console.log(err);
        });
    },
    addItem() {
      this.contract.costs.push({
        type: 'fixed',
        cost: 0,
      });
    },
    removeItem(index) {
      this.contract.costs.splice(index, 1);
    },
  },
  setup() {
    const CONTRACT_STORE_MODULE_NAME = 'contract';

    // Register contract
    if (!store.hasModule(CONTRACT_STORE_MODULE_NAME)) store.registerModule(CONTRACT_STORE_MODULE_NAME, contractStore);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CONTRACT_STORE_MODULE_NAME)) store.unregisterModule(CONTRACT_STORE_MODULE_NAME);
    });
    const contract = ref(null);
    const initialContractData = ref(null);
    const types = ref([]);
    const classifications = ref([]);
    store.dispatch('contract/fetchContractOptions').then((response) => {
      const { propertyTypes, propertyClassifications } = response.data;
      types.value = propertyTypes.map((c) => ({
        label: c.name,
        value: c.id,
        ...c,
      }));
      classifications.value = propertyClassifications.map((c) => ({
        label: c.name,
        value: c.id,
        ...c,
      }));
    });
    store.dispatch('contract/fetchContract', { id: router.currentRoute.params.id })
      .then((response) => {
        const { data } = response.data;
        contract.value = data;
        initialContractData.value = JSON.parse(JSON.stringify(contract.value));
      })
      .catch((error) => {
        if (error.response.status === 404) {
          contract.value = undefined;
        }
      });
    const statusOptions = [
      {
        label: 'في الانتظار',
        value: 'في الانتظار',
      },
      {
        label: 'مقبول',
        value: 'مقبول',
      },
      {
        label: 'مرفوض',
        value: 'مرفوض',
      },
    ];
    const payment_statusOptions = [
      {
        label: 'مدفوع',
        value: 'مدفوع',
      },
      {
        label: 'غير مدفوع',
        value: 'غير مدفوع',
      },
      {
        label: 'غير مستحق الدّفع',
        value: 'غير مستحق الدّفع',
      },
    ];
    const typeOptions = [
      {
        label: 'عقد',
        value: 'عقد',
      },
      {
        label: 'عرض سعر',
        value: 'عرض سعر',
      },
    ];
    const costTypes = [
      {
        label: 'حسب المساحة',
        value: 'fixed',
      },
      {
        label: 'حسب العقار',
        value: 'variable',
      },
    ];

    const resetcontractData = () => {
      contract.value = JSON.parse(JSON.stringify(initialContractData.value));
    };
    const { refFormObserver, getValidationState, resetForm } = formValidation(resetcontractData);
    return {
      contract,
      types,
      classifications,
      costTypes,
      payment_statusOptions,
      typeOptions,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
