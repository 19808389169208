<template>
  <b-card
    v-if="contract"
    no-body
    class="border-primary"
  >
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5 class="mb-0">
        بيانات العقد
      </h5>
      <b-badge variant="light-primary">
        {{ contract.status }}
      </b-badge>

      <small class="text-muted w-100">{{ contract.reference }}</small>
    </b-card-header>

    <b-card-body>
      <ul class="list-unstyled my-1">
        <li>
          <span class="align-middle">الجهة: <b-badge variant="light-primary">
            {{ contract.client.name }}
          </b-badge></span>
        </li>
        <li>
          <span class="align-middle"> الأتعاب : {{ contract.cost }} ريال سعودي</span>
        </li>
        <li class="my-25">
          <span class="align-middle">تاريخ العقد: من {{ contract.starts_at.split(' ')[0] }} إلى {{ contract.ends_at.split(' ')[0] }}</span>
        </li>
        <li>
          <span class="align-middle">فترةالعقد (عدد الأيام) : {{ contract.duration }}</span>
        </li>
        <li>
          <span class="align-middle">الأيام المتبقية : {{ moment(contract.ends_at, "YYYY-MM-DD").diff(moment(), 'days' ) > 0? moment(contract.ends_at, "YYYY-MM-DD").diff(moment(), 'days' ): 0 }}</span>
        </li>
        <li>
          <span class="align-middle"> حالة العقد: <b-badge :variant="contract.status == 'نشط'? 'light-primary':'light-danger'">
            {{ contract.status }}
          </b-badge></span>
        </li>
      </ul>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BBadge, BButton,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import * as moment from 'moment';
import router from '@/router';

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
  },
  props: {
    contract: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    moment(args) {
      return moment(args);
    },
  },
};
</script>

<style>

</style>
